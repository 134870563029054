<template>
  <v-container> </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service Form" },
      { title: "Report" }
    ]);
  }
};
</script>
